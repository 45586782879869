export enum LoggerCode {
  UNKNOWN_ERROR = 'S000',
  HTTP_CLIENT_ERROR = 'C001',
  APOLLO_CLIENT_ERROR = 'C002',
  GRAPHQL_SERVICE_ERROR = 'C003',
  // PRICES_RETURNS_DATA_UNAVAIL_ERROR = 'C004',
  // HOLDING_DETAILS_DATA_UNAVAIL_ERROR = 'C005',
  ROUTING_ERROR = 'C006',
  CLIENT_INFO_DATA_UNAVAIL_ERROR = 'C007',
  // VANGUARD_MUTUAL_FUND_DETAILS_DATA_UNAVAILABLE_ERROR = 'C008',
  DIST_TAX_GAINS_DATA_UNAVAIL_ERROR = 'C008',
  PAGE_LOAD = 'C09',
  DIST_TAX_GAINS_SUCCESS = 'C010',
  DIST_TAX_GAINS_QUARTER_CHANGE_SUCCESS = 'C011',
//   NON_VANGUARD_MUTUAL_FUND_DETAILS_DATA_UNAVAILABLE_ERROR = 'C012',
//   STOCK_DETAILS_DATA_UNAVAILABLE_ERROR = 'C013',
//   CD_DETAILS_DATA_UNAVAILABLE_ERROR = 'C014',
//   OPTION_DETAILS_DATA_UNAVAILABLE_ERROR = 'C015',
//   VANGUARD_ETF_DATA_UNAVAILABLE_ERROR = 'C016',
//   NON_VANGUARD_ETF_DATA_UNAVAILABLE_ERROR = 'C017',
//   APY_DATA_UNAVAILABLE_ERROR = 'C018',
//   VANGUARD_CASH_DEPOSIT_DETAILS_DATA_UNAVAILABLE_ERROR = 'C019',
//   VANGUARD_CASH_PLUS_DETAILS_DATA_UNAVAILABLE_ERROR = 'C020',
DIST_PAYOUTS_LEGACY_NAVIGATION = 'C021'
}
