import {provideRouter, Routes, withInMemoryScrolling, withRouterConfig} from "@angular/router";
import { TaxableLayoutComponent } from "@app/layout/taxable-layout/taxable-layout.component";

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'taxable-gains',
  },
  {
    path: 'taxable-gains',
    data: { pageName: 'taxable-gains' },
    loadComponent: () =>
      import('./layout/taxable-layout/taxable-layout.component').then(
        (m) => m.TaxableLayoutComponent
      ),
  },
  {
    path: 'nontaxable-gains',
    data: { pageName: 'nontaxable-gains' },
    loadComponent: () =>
      import('./layout/taxable-layout/taxable-layout.component').then(
        (m) => m.TaxableLayoutComponent
      ),
  },
  {
    path: 'distribution-payouts',
    data: { pageName: 'distribution-payouts' },
    loadComponent: () =>
      import('./layout/distributions-layout/distributions-layout.component').then(
        (m) => m.DistributionsLayoutComponent
      ),
  },
];

export const AppRouteProviders = [
  provideRouter(
    AppRoutes,
    withInMemoryScrolling({
      scrollPositionRestoration: 'disabled',
    }),
    withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
    // withEnabledBlockingInitialNavigation(),
  )
];
