<ng-container content [bundleName]="'customizeAccount'" #content="bundle">
  <div class="customize-view">
    <p>
      <span class="custom-view-pre-label">{{ content['preLinkLabel'] }}</span>
      <a
        c11n-link
        class="custom-view-link"
        [attr.data-testid]="'customize-view'"
        [attr.href]="content['url'] "
        variant="secondary-reinforced"
      >
        {{ content['label'] }}
      </a>
      <span class="custom-view-post-label">{{  content['postLinkLabel'] }}</span>
    </p>
    <br />
  </div>
</ng-container>
