import { Directive, Input, OnInit } from '@angular/core';
import {content} from "@content/content";

@Directive({
  selector: '[content]',
  exportAs: 'bundle',
  standalone: true
})
export class ContentDirective implements OnInit {
  @Input() bundleName: string;

  ngOnInit(): void {
    const bundle = this.getBundleContent(this.bundleName);
    if (bundle) {
      Object.assign(this, bundle);
    }
  }

  /**
   * Return content for given path.
   * @param path dot-separated path to head of content to be provided. Does not support array notation.
   */
  private getBundleContent(path: string): unknown | undefined {
    return path ?
      path.split('.')
        .reduce((parentObject, propertyName) => {
            return (parentObject as any)?.[propertyName];
          },
          content,
        )
      : undefined;

  }
}
