import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Account = {
  __typename?: 'Account';
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  managed?: Maybe<Scalars['Boolean']>;
  marginCode?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<AccountPosition>>;
  productType?: Maybe<Scalars['String']>;
  upgradedToVBA?: Maybe<Scalars['Boolean']>;
  upgradedToVBAMergedateTime?: Maybe<Scalars['String']>;
};

export type AccountAgg = {
  __typename?: 'AccountAgg';
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  managed?: Maybe<Scalars['Boolean']>;
  marginCode?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<AccountAggPosition>>;
  productType?: Maybe<Scalars['String']>;
  upgradedToVBA?: Maybe<Scalars['Boolean']>;
  upgradedToVBAMergedateTime?: Maybe<Scalars['String']>;
};

export type AccountAggPosition = {
  __typename?: 'AccountAggPosition';
  balance?: Maybe<Scalars['Float']>;
  cusip?: Maybe<Scalars['String']>;
  fundAccountId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  ticker?: Maybe<Scalars['String']>;
  todayTradeDateBalance?: Maybe<Scalars['Float']>;
  tradeDateBalance?: Maybe<Scalars['Float']>;
};

export type AccountAggregatorData = {
  __typename?: 'AccountAggregatorData';
  accounts: Array<Account>;
  clientPoId?: Maybe<Scalars['Float']>;
  partialErrors?: Maybe<Array<PartialError>>;
  total: Total;
};

export type AccountByDate = {
  __typename?: 'AccountByDate';
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  positions?: Maybe<Array<AccountPositionByDate>>;
  productType?: Maybe<Scalars['String']>;
};

export type AccountByDateData = {
  __typename?: 'AccountByDateData';
  accounts: Array<AccountByDate>;
  clientPoId?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Errors>>;
  participantYearEnds?: Maybe<Array<ParticipantYearEnds>>;
};

export type AccountPayoutsData = {
  __typename?: 'AccountPayoutsData';
  accounts?: Maybe<Array<DtgAccountPayouts>>;
  clientPoId?: Maybe<Scalars['Float']>;
  hasEmployerPlan?: Maybe<Scalars['Boolean']>;
  partialErrors?: Maybe<Array<PartialErrorPayouts>>;
  summaryTotals?: Maybe<SummaryTotalPayouts>;
};

export type AccountPosition = {
  __typename?: 'AccountPosition';
  balance?: Maybe<Scalars['Float']>;
  cusip?: Maybe<Scalars['String']>;
  fundAccountId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  ticker?: Maybe<Scalars['String']>;
  todayTradeDateBalance?: Maybe<Scalars['Float']>;
  tradeDateBalance?: Maybe<Scalars['Float']>;
};

export type AccountPositionByDate = {
  __typename?: 'AccountPositionByDate';
  balance?: Maybe<Scalars['Float']>;
  cusip?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceAsOfDateTime?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  shareQuantity?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type DtgAccount = {
  __typename?: 'DTGAccount';
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountTotalDistributions?: Maybe<Scalars['Float']>;
  accountTotalDividends?: Maybe<Scalars['Float']>;
  accountTotalInterest?: Maybe<Scalars['Float']>;
  accountTotalLongTermCapitalGains?: Maybe<Scalars['Float']>;
  accountTotalOtherIncome?: Maybe<Scalars['Float']>;
  accountTotalShortTermCapitalGains?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  cashManagementAccount?: Maybe<Scalars['Boolean']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  marginCode?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<DtgAccountPosition>>;
  productType?: Maybe<Scalars['String']>;
  upgradedToVBA?: Maybe<Scalars['Boolean']>;
  upgradedToVBAMergedateTime?: Maybe<Scalars['String']>;
};

export type DtgAccountData = {
  __typename?: 'DTGAccountData';
  accounts?: Maybe<Array<DtgAccount>>;
  clientPoId?: Maybe<Scalars['Float']>;
  hasEmployerPlan?: Maybe<Scalars['Boolean']>;
  partialErrors?: Maybe<Array<PartialError>>;
  summaryTotals?: Maybe<SummaryTotal>;
};

export type DtgAccountPayouts = {
  __typename?: 'DTGAccountPayouts';
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  cashManagementAccount?: Maybe<Scalars['Boolean']>;
  grossAmountTotal?: Maybe<Scalars['Float']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  marginCode?: Maybe<Scalars['String']>;
  payoutPositions?: Maybe<Array<DtgPayoutsPositions>>;
  planId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  upgradedToVBA?: Maybe<Scalars['Boolean']>;
  upgradedToVBAMergedateTime?: Maybe<Scalars['String']>;
};

export type DtgAccountPosition = {
  __typename?: 'DTGAccountPosition';
  balance?: Maybe<Scalars['Float']>;
  cusip?: Maybe<Scalars['String']>;
  dividends?: Maybe<Scalars['Float']>;
  fundAccountId?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['Float']>;
  longTermCapitalGains?: Maybe<Scalars['Float']>;
  otherIncome?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  shortTermCapitalGains?: Maybe<Scalars['Float']>;
  ticker?: Maybe<Scalars['String']>;
  todayTradeDateBalance?: Maybe<Scalars['Float']>;
  totalDistributions?: Maybe<Scalars['Float']>;
  tradeDateBalance?: Maybe<Scalars['Float']>;
};

export type DtgDisbursements = {
  __typename?: 'DTGDisbursements';
  distributionRate?: Maybe<Scalars['Float']>;
  election?: Maybe<Scalars['String']>;
  exReinvestDate?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<Scalars['Float']>;
  paidDate?: Maybe<Scalars['String']>;
  reinvestPrice?: Maybe<Scalars['Float']>;
  sharesTransacted?: Maybe<Scalars['Float']>;
  transactionDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DtgPayoutsPositions = {
  __typename?: 'DTGPayoutsPositions';
  cusip?: Maybe<Scalars['String']>;
  disbursements?: Maybe<Array<DtgDisbursements>>;
  fundAccountId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  ticker?: Maybe<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  accountId?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
};

export type MergeExpandHistory = {
  __typename?: 'MergeExpandHistory';
  destinationAccountId?: Maybe<Scalars['String']>;
  mergeDateTime?: Maybe<Scalars['String']>;
  sourceAccountId?: Maybe<Scalars['String']>;
  sourceAccountProductType?: Maybe<Scalars['String']>;
};

export type MergeExpandHistoryData = {
  __typename?: 'MergeExpandHistoryData';
  mergeExpandHistoryAccounts?: Maybe<Array<MergeExpandHistory>>;
};

export type PartialError = {
  __typename?: 'PartialError';
  accountId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PartialErrorPayouts = {
  __typename?: 'PartialErrorPayouts';
  accountId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ParticipantYearEnds = {
  __typename?: 'ParticipantYearEnds';
  planId?: Maybe<Scalars['String']>;
  planName?: Maybe<Scalars['String']>;
  yearEndAsOfDate?: Maybe<Scalars['String']>;
  yearEndBalance?: Maybe<Scalars['Float']>;
};

export type PayoutTotalInfo = {
  __typename?: 'PayoutTotalInfo';
  contains403b?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalBalanceAsOfDate?: Maybe<Scalars['String']>;
  totalBalanceManaged?: Maybe<Scalars['Float']>;
  totalBalanceSelfManaged?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  DTGAccountData: DtgAccountData;
  MergeExpandHistoryData: MergeExpandHistoryData;
  accountAggregator: AccountAggregatorData;
  balanceByDate: AccountByDateData;
  payouts: Array<Payouts>;
  payoutsData: AccountPayoutsData;
  sayHello: Scalars['String'];
  taxableNonTaxable: Array<TaxableNonTaxable>;
  taxableNonTaxableData: DtgAccountData;
};


export type QueryDtgAccountDataArgs = {
  endDate: Scalars['String'];
  resourceType: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryBalanceByDateArgs = {
  date: Scalars['String'];
  yearend: Scalars['Boolean'];
};


export type QueryPayoutsArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryPayoutsDataArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryTaxableNonTaxableArgs = {
  endDate: Scalars['String'];
  resourceType: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryTaxableNonTaxableDataArgs = {
  endDate: Scalars['String'];
  resourceType: Scalars['String'];
  startDate: Scalars['String'];
};

export type SummaryTotal = {
  __typename?: 'SummaryTotal';
  managedBalance?: Maybe<Scalars['Float']>;
  selfManagedBalance?: Maybe<Scalars['Float']>;
  totalDistributions?: Maybe<Scalars['Float']>;
  totalDividends?: Maybe<Scalars['Float']>;
  totalInterest?: Maybe<Scalars['Float']>;
  totalLongTermCapitalGains?: Maybe<Scalars['Float']>;
  totalOtherIncome?: Maybe<Scalars['Float']>;
  totalShortTermCapitalGains?: Maybe<Scalars['Float']>;
};

export type SummaryTotalPayouts = {
  __typename?: 'SummaryTotalPayouts';
  managedBalance?: Maybe<Scalars['Float']>;
  selfManagedBalance?: Maybe<Scalars['Float']>;
  totalGrossAmount?: Maybe<Scalars['Float']>;
};

export type Total = {
  __typename?: 'Total';
  contains403b?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalBalanceAsOfDate?: Maybe<Scalars['String']>;
  totalBalanceManaged?: Maybe<Scalars['Float']>;
  totalBalanceSelfManaged?: Maybe<Scalars['Float']>;
};

export type Disbursements = {
  __typename?: 'disbursements';
  distributionRate?: Maybe<Scalars['Float']>;
  election?: Maybe<Scalars['String']>;
  exReinvestDate?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<Scalars['Float']>;
  paidDate?: Maybe<Scalars['String']>;
  reinvestPrice?: Maybe<Scalars['Float']>;
  sharesTransacted?: Maybe<Scalars['Float']>;
  transactionDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HdcPositions = {
  __typename?: 'hdcPositions';
  date?: Maybe<Scalars['String']>;
  distributionAmount?: Maybe<Scalars['Float']>;
  dividend?: Maybe<Scalars['Float']>;
  instrumentId?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['Float']>;
  longTermGain?: Maybe<Scalars['Float']>;
  otherIncome?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  shortTermGain?: Maybe<Scalars['Float']>;
};

export type Payouts = {
  __typename?: 'payouts';
  accountId?: Maybe<Scalars['Float']>;
  grossAmountTotal?: Maybe<Scalars['Float']>;
  positions?: Maybe<Array<PayoutsPositions>>;
};

export type PayoutsPositions = {
  __typename?: 'payoutsPositions';
  disbursements?: Maybe<Array<Disbursements>>;
  positionId?: Maybe<Scalars['String']>;
};

export type TaxableNonTaxable = {
  __typename?: 'taxableNonTaxable';
  accountId?: Maybe<Scalars['Float']>;
  positions?: Maybe<Array<HdcPositions>>;
};

export type DistTaxGainsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  resourceType: Scalars['String'];
}>;


export type DistTaxGainsQuery = (
  { __typename?: 'Query' }
  & { taxableNonTaxableData: (
    { __typename?: 'DTGAccountData' }
    & Pick<DtgAccountData, 'clientPoId'>
    & { accounts?: Maybe<Array<(
      { __typename?: 'DTGAccount' }
      & Pick<DtgAccount, 'accountId' | 'accountName' | 'accountTotalDistributions' | 'accountTotalDividends' | 'accountTotalInterest' | 'accountTotalLongTermCapitalGains' | 'accountTotalOtherIncome' | 'accountTotalShortTermCapitalGains' | 'balance' | 'cashManagementAccount' | 'isManaged' | 'marginCode' | 'planId' | 'upgradedToVBA' | 'upgradedToVBAMergedateTime' | 'productType'>
      & { positions?: Maybe<Array<(
        { __typename?: 'DTGAccountPosition' }
        & Pick<DtgAccountPosition, 'balance' | 'cusip' | 'dividends' | 'fundAccountId' | 'interest' | 'longTermCapitalGains' | 'otherIncome' | 'positionId' | 'positionType' | 'securityName' | 'securityType' | 'settlementFund' | 'shortTermCapitalGains' | 'ticker' | 'todayTradeDateBalance' | 'totalDistributions' | 'tradeDateBalance'>
      )>> }
    )>>, summaryTotals?: Maybe<(
      { __typename?: 'SummaryTotal' }
      & Pick<SummaryTotal, 'managedBalance' | 'selfManagedBalance' | 'totalDistributions' | 'totalDividends' | 'totalInterest' | 'totalLongTermCapitalGains' | 'totalOtherIncome' | 'totalShortTermCapitalGains'>
    )>, partialErrors?: Maybe<Array<(
      { __typename?: 'PartialError' }
      & Pick<PartialError, 'accountId' | 'code' | 'description'>
    )>> }
  ), accountAggregator: (
    { __typename?: 'AccountAggregatorData' }
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'accountId' | 'productType'>
    )> }
  ) }
);

export type DistributionPayoutsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type DistributionPayoutsQuery = (
  { __typename?: 'Query' }
  & { payoutsData: (
    { __typename?: 'AccountPayoutsData' }
    & Pick<AccountPayoutsData, 'clientPoId'>
    & { accounts?: Maybe<Array<(
      { __typename?: 'DTGAccountPayouts' }
      & Pick<DtgAccountPayouts, 'accountId' | 'accountName' | 'balance' | 'cashManagementAccount' | 'grossAmountTotal' | 'isManaged' | 'marginCode' | 'planId' | 'productType' | 'upgradedToVBA' | 'upgradedToVBAMergedateTime'>
      & { payoutPositions?: Maybe<Array<(
        { __typename?: 'DTGPayoutsPositions' }
        & Pick<DtgPayoutsPositions, 'cusip' | 'fundAccountId' | 'positionId' | 'positionType' | 'securityName' | 'securityType' | 'settlementFund' | 'ticker'>
        & { disbursements?: Maybe<Array<(
          { __typename?: 'DTGDisbursements' }
          & Pick<DtgDisbursements, 'type' | 'frequency' | 'election' | 'exReinvestDate' | 'paidDate' | 'distributionRate' | 'reinvestPrice' | 'sharesTransacted' | 'grossAmount' | 'transactionDate'>
        )>> }
      )>> }
    )>>, summaryTotals?: Maybe<(
      { __typename?: 'SummaryTotalPayouts' }
      & Pick<SummaryTotalPayouts, 'managedBalance' | 'selfManagedBalance' | 'totalGrossAmount'>
    )>, partialErrors?: Maybe<Array<(
      { __typename?: 'PartialErrorPayouts' }
      & Pick<PartialErrorPayouts, 'accountId' | 'code' | 'description'>
    )>> }
  ), accountAggregator: (
    { __typename?: 'AccountAggregatorData' }
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'accountId' | 'productType'>
    )> }
  ) }
);

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sayHello'>
);

export const DistTaxGainsDocument = gql`
    query distTaxGains($startDate: String!, $endDate: String!, $resourceType: String!) {
  taxableNonTaxableData(
    startDate: $startDate
    endDate: $endDate
    resourceType: $resourceType
  ) {
    clientPoId
    accounts {
      accountId
      accountName
      accountTotalDistributions
      accountTotalDividends
      accountTotalInterest
      accountTotalLongTermCapitalGains
      accountTotalOtherIncome
      accountTotalShortTermCapitalGains
      balance
      cashManagementAccount
      isManaged
      marginCode
      planId
      upgradedToVBA
      upgradedToVBAMergedateTime
      positions {
        balance
        cusip
        dividends
        fundAccountId
        interest
        longTermCapitalGains
        otherIncome
        positionId
        positionType
        securityName
        securityType
        settlementFund
        shortTermCapitalGains
        ticker
        todayTradeDateBalance
        totalDistributions
        tradeDateBalance
      }
      productType
    }
    summaryTotals {
      managedBalance
      selfManagedBalance
      totalDistributions
      totalDividends
      totalInterest
      totalLongTermCapitalGains
      totalOtherIncome
      totalShortTermCapitalGains
    }
    partialErrors {
      accountId
      code
      description
    }
  }
  accountAggregator {
    accounts {
      accountId
      productType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DistTaxGainsGQL extends Apollo.Query<DistTaxGainsQuery, DistTaxGainsQueryVariables> {
    document = DistTaxGainsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DistributionPayoutsDocument = gql`
    query distributionPayouts($startDate: String!, $endDate: String!) {
  payoutsData(startDate: $startDate, endDate: $endDate) {
    clientPoId
    accounts {
      accountId
      accountName
      balance
      cashManagementAccount
      grossAmountTotal
      isManaged
      marginCode
      planId
      productType
      upgradedToVBA
      upgradedToVBAMergedateTime
      payoutPositions {
        cusip
        fundAccountId
        positionId
        positionType
        securityName
        securityType
        settlementFund
        ticker
        disbursements {
          type
          frequency
          election
          exReinvestDate
          paidDate
          distributionRate
          reinvestPrice
          sharesTransacted
          grossAmount
          transactionDate
        }
      }
    }
    summaryTotals {
      managedBalance
      selfManagedBalance
      totalGrossAmount
    }
    partialErrors {
      accountId
      code
      description
    }
  }
  accountAggregator {
    accounts {
      accountId
      productType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DistributionPayoutsGQL extends Apollo.Query<DistributionPayoutsQuery, DistributionPayoutsQueryVariables> {
    document = DistributionPayoutsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TestDocument = gql`
    query test {
  sayHello
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TestGQL extends Apollo.Query<TestQuery, TestQueryVariables> {
    document = TestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }