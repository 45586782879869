<c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
<main id="app-main-content" role="main">
  <ss-lib-page-layout>
    <div inner>
      <div class="row">
        <div class="col">
          <ss-lib-secondary-nav widgetActiveItem="InvestmentsTab" [displayPrintLink]="true"></ss-lib-secondary-nav>
        </div>
      </div>

      <ng-container class="row">
        <div class="col">
          <dtg-customize-view></dtg-customize-view>
        </div>
      </ng-container>

      <div class="row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ss-lib-page-layout>
</main>

<div class="d-none">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="1000" height="1000">
    <symbol id="check-small" viewBox="0 0 14 14">
      <path d="M10.715,3.912 C10.8713243,4.03506505 10.9721806,4.21533706 10.9952631,4.41294647 C11.0183457,4.61055588 10.9617526,4.80921943 10.838,4.965 L6.906,9.933 C6.71308373,10.227772 6.40465388,10.4273661 6.05672119,10.4825935 C5.70878851,10.5378209 5.35371265,10.4435452 5.079,10.223 L3.292,8.844 C2.96394286,8.59105358 2.90305358,8.12005714 3.156,7.792 C3.40894642,7.46394286 3.87994286,7.40305358 4.208,7.656 L5.618,8.745 C5.72713228,8.82771009 5.88241757,8.80768763 5.967,8.7 L9.667,4.033 C9.92357005,3.7112448 10.3918594,3.65717705 10.715,3.912 Z"></path>
    </symbol>
  </svg>
</div>
