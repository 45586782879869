export const directives = {
  'default-src': ["'self'", '*.vanguard.com:*', '*.vgcontent.info:*', '*.kampyle.com'],
  'script-src': [
    "'self'",
    '*.vanguard.com:*',
    '*.vgcontent.info:*',
    '*.demdex.net',
    'fls.doubleclick.net',
    '*.fls.doubleclick.net',
    'vanguard.d2.sc.omtrdc.net',
    "'unsafe-eval'",
    '*.omniture.com',
    'activitymap.adobe.com',
    '*.vgdynamic.info',
    '*.kampyle.com',
    "'unsafe-inline'",
    '*.tt.omtrdc.net',
  ],
  'style-src': [
    "'self'",
    '*.vanguard.com:*',
    '*.vgcontent.info:*',
    '*.kampyle.com',
    "'unsafe-inline'",
    '*.tt.omtrdc.net',
  ],
  'connect-src': [
    "'self'",
    '*.vanguard.com:*',
    '*.vgcontent.info:*',
    '*.demdex.net',
    'fls.doubleclick.net',
    '*.fls.doubleclick.net',
    'vanguard.d2.sc.omtrdc.net',
    '*.eum-appdynamics.com',
    '*.kampyle.com',
    '*.medallia.com',
    '*.tt.omtrdc.net',
    'privacyportal-de.onetrust.com',
    'geolocation.onetrust.com',
    'cdn.cookielaw.org',
  ],
  'img-src': [
    "'self'",
    '*.vanguard.com:*',
    '*.vgcontent.info:*',
    '*.demdex.net',
    'fls.doubleclick.net',
    '*.fls.doubleclick.net',
    'vanguard.d2.sc.omtrdc.net',
    '*.eum-appdynamics.com',
    't.myvisualiq.net',
    'data:',
    '*.vgdynamic.info',
    '*.kampyle.com',
    '*.facebook.com',
    '*.amazon-adsystem.com',
    '*.analytics.yahoo.com',
    'insight.adsrvr.org',
    '*.ads.linkedin.com',
    'p.adsymptotic.com',
  ],
  'frame-src': [
    "'self'",
    '*.vanguard.com:*',
    '*.vgcontent.info:*',
    '*.demdex.net',
    'fls.doubleclick.net',
    '*.fls.doubleclick.net',
    'vanguard.d2.sc.omtrdc.net',
    '*.omniture.com',
    'activitymap.adobe.com',
    '*.kampyle.com',
    'insight.adsrvr.org',
  ],
  'object-src': ["'self'", '*.vanguard.com:*', '*.vgcontent.info:*'],
};

export const script_policies: string = directives['script-src'].join(' ');
export const img_policies: string = directives['img-src'].join(' ');
export const style_policies: string = directives['style-src'].join(' ');
export const connect_policies: string = directives['connect-src'].join(' ');

export const frames_policies: string = directives['frame-src'].join(' ');

export const object_policies: string = directives['object-src'].join(' ');

export const csp: { [policy: string]: string } = Object.keys(directives).reduce(
  (csp, policy) => ({ ...csp, [policy]: directives[policy].join(' ') }),
  {},
);
