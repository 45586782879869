import './polyfills';

import {bootstrapApplication, enableDebugTools} from '@angular/platform-browser';
import {AppComponent} from '@app/app.component';
import {AppRouteProviders} from '@app/route.providers';
import {HttpProviders} from '@app/http.providers';
import {InitializeProviders} from "@app/init.providers";
import {Apollo} from "apollo-angular";
import {DistTaxGainsGQL} from "@generated/graphql";
import {GraphQLProvider} from "@app/graphql.provider";
import {ModalDialogModule} from "@vg-constellation/angular-15/modal-dialog";
import {importProvidersFrom} from "@angular/core";


bootstrapApplication(AppComponent, {
  providers: [
    Apollo,
    DistTaxGainsGQL,
    ...HttpProviders,
    ...AppRouteProviders,
    ...InitializeProviders,
    ...GraphQLProvider,
    importProvidersFrom(ModalDialogModule.forRoot())

  ],
}).then((appRef)=> {
  // Run this in console to check Detection Cycle execution time
  // ng.profiler.timeChangeDetection({record: true})
  let cmp = appRef.components[0]
  enableDebugTools(cmp);
}).catch(err => console.log(err));
